$(function(){
    initMobileMenu();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.uno-mfp-popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 5000);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();


    function initSearchBar() {
        $('#search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    $('#menu nav li:has(ul)').doubleTapToGo();
});

function initMobileMenu() {
    var menu = $('#menu nav').clone(),
        mobileMenu = $('#mobile-menu');
    mobileMenu.append(menu.html()).mmenu({
        offCanvas: {
            position: "right"
        }
    });
}

$(document).ready(function() {
    $('#menu nav > ul > li > ul').each(function() {
        w = $(this).width();
        $(this).css('left','50%');
        $(this).css('margin-left',(w/-2));
    });
});